<template>
    <Loading :isLoading="isLoading" />
    <html>
      <body class="" style="background: #FFF">
        <HeaderMobil :localNavigation="false" v-on:go-to-back="changeGoToBack" />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="bg-container col-span-6">
                <div v-if="!continueQuote" class="w-full md:w-4/5 mx-auto h-auto py-10 md:py-16 grid grid-cols-1 md:grid-cols-2">
                    <div class="px-5">
                        <p class="text-2xl md:text-3xl font-bold mb-5 md:mb-10 RobotoBold">Datos del Solicitante</p>
                        <div class="border shadow rounded border-gray-100 px-5 py-4">
                            <p class="text-lg font-light mb-5 RobotoMedium">Datos Personales</p>  
                            
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Nombre completo:</p> 
                                <p class="text-md font-light mb-2 RobotoLight">
                                    {{ detail.personal_data.full_name }}
                                </p>
                            </div>
                            <div class="grid grid-cols-2">
                                <div class="mb-2">
                                    <p class="text-sm font-light RobotoLight">Sexo:</p> 
                                    <p class="text-md font-light RobotoLight">{{ (detail.personal_data.gender.toLowerCase() == 'male')? 'Masculino': 'Femenino' }} </p>
                                </div>
                                <div class="mb-2">
                                    <p class="text-sm font-light RobotoLight">Edad:</p> 
                                    <p class="text-md font-light RobotoLight">{{ detail.personal_data.age }} años </p>
                                </div>
                            </div>
                            <div class="grid grid-cols-2">
                                <div class="mb-2"><p class="text-sm font-light RobotoLight">Estatura:</p> <p class="text-md font-light RobotoLight">{{ $filters.formatMeters(detail.personal_data.height) }} </p></div>
                                <div class="mb-2"><p class="text-sm font-light RobotoLight">Peso:</p> <p class="text-md font-light RobotoLight">{{ detail.personal_data.weight }} Kg </p></div>
                            </div>
                            <div class="mb-3 mt-5"><p class="text-lg font-light mb-2 RobotoMedium">Suma asegurada</p></div>
                            <div class="mb-2">
                                <p class="text-md font-light RobotoLight">{{  $filters.formatCurrencyWithDecimal(detail.assured_amount) }}</p>
                            </div>
                            
                            <div class="mb-5 mt-5"><p class="text-lg font-light mb-5 RobotoMedium">Contacto</p></div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Teléfono:</p> 
                                <p class="text-md font-light RobotoLight">{{ $filters.formatPhone(detail.contact_data.telephone) }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Correo:</p> 
                                <p class="text-md font-light RobotoLight">{{ detail.contact_data.email }}</p>
                            </div>

                            <div class="grid grid-cols-2 grid-rows-3 mb-5 mt-10">
                                <div class="mb-5 mt-5"> 
                                    <p class="text-md font-light RobotoRegular">Enfermedades:</p> 
                                    <div class="text-sm RobotoLight ml-2 capitalize">
                                        <p v-if="detail.diseases.length==0">No</p>
                                        <p v-for="(desease, index) in detail.diseases" :key="index" class="mb-1">{{ desease.replaceAll('_', ' ') }}</p>
                                    </div>
                                </div>
                                <div class="mb-5 mt-5"> 
                                    <p class="text-md font-light RobotoRegular">Deportes:</p> 
                                    <p class="text-sm RobotoLight ml-2 capitalize"> {{ (detail.sports.length>0)? 'Si': 'No' }} </p>
                                    <div class="text-sm RobotoLight ml-2 capitalize">
                                        <p v-for="(sport, index) in detail.sports" :key="index">{{ sport.replaceAll('_', ' ') }}</p>
                                    </div>
                                </div>
                                <div class="mb-5 mt-5"> 
                                    <p class="text-md font-light RobotoRegular">Profesión:</p> 
                                    <div class="text-sm RobotoLight ml-2 capitalize">
                                        <p v-if="detail.occupation.length==0">No</p>
                                        <p v-for="(occupation, index) in detail.occupation" :key="index">{{ occupation.replaceAll('_', ' ') }}</p>
                                    </div>
                                </div>
                                <div class="mb-5 mt-5"> 
                                    <p class="text-md font-light RobotoRegular">Operaciones:</p> 
                                    <div class="text-sm RobotoLight ml-2 capitalize">
                                        <p v-if="detail.surgery.length==0">No</p>
                                        <p v-for="(surgery, index) in detail.surgery" :key="index">{{ surgery.replaceAll('_', ' ') }}</p>
                                    </div>
                                </div>
                                <div class="mb-5 mt-5"> 
                                    <p class="text-md font-light RobotoRegular">Pasatiempos:</p> 
                                    <div class="text-sm RobotoLight ml-2 capitalize">
                                        <p v-if="detail.hobbies.length==0">No</p>
                                        <p v-for="(hobby, index) in detail.hobbies" :key="index">{{ hobby.replaceAll('_', ' ') }}</p>
                                    </div>
                                </div>
                                <div class="mb-5 mt-5"> 
                                    <p class="text-md font-light RobotoRegular"> </p>
                                </div>
                            </div>

                        </div>
                        <input type="button" class="bg-mustardDark cursor-pointer text-white px-5 py-2 mx-auto my-5 w-full h-14 md:h-14  rounded text-center font-medium text-xl" @click="confirm" value="Continuar">
                    </div>
                    <div class="px-10 py-16 hidden md:block">
                        <img :src="require('@/assets/Cuenta/vidaSimple/finished.svg')" alt="FONCABSA" class="mx-auto">
                    </div>
                </div>
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import { onMounted, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';

import MenuPrincipal from "@/components/Cuenta/MenuPrincipal.vue";
import HeaderMobil from "@/components/Cuenta/HeaderMobil.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';;
import MenuLateral from '@/components/LateralMenu.vue';;
import Footer from '@/components/Footer.vue';
import { getTokenDecoden, getUserInfo } from '@/helpers/tokenauth.js';

import moment from "moment";
import Swal from "sweetalert2";
import Quote from "@/classes/Quote";
import Loading from '@/components/Loading/VueLoading.vue';


export default {
    setup(){
        const token = ref('');
        const quoteData = ref({});
        const userData = ref({});
        const userId = ref(null);

        const router = useRouter();
        const category = ref('');

        let counterGoBack = ref(0);
        let continueQuote = ref(false);

        let detail = ref({
            personal_data:{
                full_name: "",
                age: 0,
                gender: "",
                height: 0,
                weight: 0
            },
            contact_data: {
                telephone: "",
                email: ""
            },
            diseases: [],
            sports: [],
            occupation: [],
            surgery: [],
            hobbies: [],
            coverages: [],
            assured_amount: 0,
            smoke: ''
        });

        const quoteClass = ref(null);
        const isLoading = ref(true)

        onBeforeMount(async()=>{
            quoteClass.value = new Quote()
            if(storageExist('quote')){
                await loadInfo();
                getTokenQuotation();
                return;
            }

            router.push({path: '/cuenta/principal'});
        })

        onMounted(()=>{
            isLoading.value = false;
        })

        const loadInfo = async() => {
            const userInfo = getTokenDecoden();
            quoteData.value = getStorage('quote-data');
            userData.value = getUserInfo();
            token.value = getSessionStorage('login');
            userId.value = userInfo.obj.person.fonsnte_user_id;
            
            if(quoteData.value.contracting_data.contractor_other_than_the_applicant== 'no'){
                await getAccountInfo(quoteData.value);
            }else if(quoteData.value.contracting_data.contractor_other_than_the_applicant== 'si'){
                await getAdditionalApplicantInfo();
            }

        }

        const getAccountInfo = async () => {
            const userInfo = getTokenDecoden();
            const userId = userInfo.obj.person.fonsnte_user_id;
            
            let userInfoQuote = await getUserInformation(token.value, userId);
            if(userInfoQuote.has_errors){
                return;
            }

            detail.value.personal_data.full_name = userData.value.full_name;

            let birthday = moment(userInfo.obj.person.birthday);
            let today = moment();
            let age = today.diff(birthday,"years");

            detail.value.personal_data.age = (age)? age: 0;
            detail.value.personal_data.weight = Number(Number.parseFloat(quoteData.value.personal_info.weight).toFixed(2));
            detail.value.personal_data.height = Number(Number.parseFloat(quoteData.value.personal_info.height.toString().replaceAll('.', '')).toFixed(2));
            detail.value.personal_data.gender = (userInfo.obj.person.gender)? (userInfo.obj.person.gender=="femenino")? 'female': 'male' :'';

            detail.value.contact_data.email = (userInfoQuote.simple_life_info.contact_details.email)? userInfoQuote.simple_life_info.contact_details.email:'';
            detail.value.contact_data.telephone = (userInfoQuote.simple_life_info.contact_details.phone)? userInfoQuote.simple_life_info.contact_details.phone: '' ;
            
            detail.value.diseases = (quoteData.value.diseases.diseases)?quoteData.value.diseases.diseases.concat(quoteData.value.diseases.other_diseases) : [];
            detail.value.sports = (quoteData.value.sports.sports)?quoteData.value.sports.sports : [];
            detail.value.surgery = (quoteData.value.operations.operations)?quoteData.value.operations.operations : [];
            detail.value.hobbies = (quoteData.value.hobbies.hobbies)?quoteData.value.hobbies.hobbies : [];

            let coverages = quoteData.value.coverages.added_benefit.filter(cov => {
                if(cov.value == true){
                    return cov.option;
                }
            });

            coverages = coverages.map(cov=> cov.option);
            coverages.push('basic');

            detail.value.coverages = (coverages.length>0)? coverages : [];
            detail.value.assured_amount = (quoteData.value.coverages.amount_insured)?quoteData.value.coverages.amount_insured : 0;
            detail.value.smoke = (quoteData.value.smoke.tobacco_type_list)? quoteData.value.smoke.tobacco_type_list: '';

            detail.value.occupation = (userInfoQuote.simple_life_info.work_info.occupation)? [userInfoQuote.simple_life_info.work_info.occupation] : [];
        }

        const getAdditionalApplicantInfo = async () => {
            const userInfo = getTokenDecoden();
            const userId = userInfo.obj.person.fonsnte_user_id;
            
            let userInfoQuote = await getUserInformation(token.value, userId.value);

            if(userInfoQuote.has_errors){
                return;
            }

            detail.value.personal_data.full_name = `${quoteData.value.contracting_data.input_nombre_contracting_data} ${quoteData.value.contracting_data.input_paternal_name_contracting_data} ${quoteData.value.contracting_data.input_maternal_name_contracting_data}`;

            let birthday = moment(quoteData.value.contracting_data.birthdate_contracting_data, "DD/MM/YYYY");
            
            let today = moment();
            let age = today.diff(birthday, "years");
            
            detail.value.personal_data.age = (age)? age: 0;
            detail.value.personal_data.weight = Number(Number.parseFloat(quoteData.value.contracting_data.weight).toFixed(2));
            detail.value.personal_data.height = Number(Number.parseFloat(quoteData.value.contracting_data.height.toString().replaceAll('.', '')).toFixed(2));
            detail.value.personal_data.gender = (quoteData.value.contracting_data.gender_contracting_data)? (quoteData.value.contracting_data.gender_contracting_data=="femenino")? 'female': 'male' :'';

            detail.value.contact_data.email = (quoteData.value.contractor_contact.email_contractor_contact)? quoteData.value.contractor_contact.email_contractor_contact:'';
            detail.value.contact_data.telephone = (quoteData.value.contractor_contact.cellphone_contractor_contact)? quoteData.value.contractor_contact.cellphone_contractor_contact: '' ;
            
            detail.value.diseases = (quoteData.value.diseases.diseases)?quoteData.value.diseases.diseases.concat(quoteData.value.diseases.other_diseases) : [];
            detail.value.sports = (quoteData.value.sports.sports)?quoteData.value.sports.sports : [];
            detail.value.surgery = (quoteData.value.operations.operations)?quoteData.value.operations.operations : [];
            detail.value.hobbies = (quoteData.value.hobbies.hobbies)?quoteData.value.hobbies.hobbies : [];

            let coverages = quoteData.value.coverages.added_benefit.filter(cov => {
                if(cov.value == true){
                    return cov.option;
                }
            });

            coverages = coverages.map(cov=> cov.option);
            coverages.push('basic');

            detail.value.coverages = (coverages.length>0)? coverages : [];
            detail.value.assured_amount = (quoteData.value.coverages.amount_insured)?quoteData.value.coverages.amount_insured : 0;
            detail.value.smoke = (quoteData.value.smoke.tobacco_type_list)? quoteData.value.smoke.tobacco_type_list: '';

            detail.value.occupation = (quoteData.value.profession_occupation.occupation)? quoteData.value.profession_occupation.occupation : [];
        }

        const getUserInformation = async (token, userId) => {
            let userInfo = quoteClass.value.getUserInformation(token, userId).then(resp => resp.data.response).catch(err => {
                if(err.response.status != 500) {
                    console.log("!Error 422:", err.response.data.response.message)
                    Swal.fire({
                        title: "Aviso",
                        html: err.response.data.response.message,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }else{
                    Swal.fire({
                        title: "Aviso",
                        html: "Ha ocurrido un error al obtener la información su cuenta.",
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }
                return null;
            });

            if(userInfo.has_errors){
                Swal.fire({
                    title: "Aviso",
                    html: userInfo.message,
                    icon: "info",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
            }

            return userInfo;
        }

        const createStorage = (name, value) => {
            localStorage.setItem(name, JSON.stringify(value));
        }
        
        const getStorage = (name) => {
            return JSON.parse(localStorage.getItem(name));
        }

        const getSessionStorage = (name) => {
            return sessionStorage.getItem(name);
        }

        const storageExist = (name) => {
            return (localStorage.getItem(name))? true: false;
        }

        function isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        }

        const changeGoToBack = () => {
            counterGoBack.value = counterGoBack.value+1;
        }

        const next = () => {
            router.push({path:'/cuenta/cotizacion/vida-simple/finalizar'})
        }

        const getTokenQuotation = async () => {
            token.value = await quoteClass.value.getToken().then(resp => resp.data.response.token_auth ).catch(err => {
                if(err.response.status != 500) {
                    console.log("!Error 422:", err.response.data.response.message)
                    Swal.fire({
                        title: "Aviso",
                        html: err.response.data.response.message,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }else{
                    Swal.fire({
                        title: "Aviso",
                        html: "Ha ocurrido un error con el servicio de cotización",
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }
                return null;
            });
        }

        const generateQuotation = async(values) => {
            return await quoteClass.value.post(token.value, values).then(resp => resp.data.response ).catch(err => {
            
                if(err.response.status == 422) {
                    console.log("!Error 422:", err.response.data.response.message)
                    Swal.fire({
                        title: "Aviso",
                        html: err.response.data.response.message,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }else{
                    Swal.fire({
                        title: "Aviso",
                        html: "Ha ocurrido un error con el servicio de cotización",
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }
                return null;
            });
        }

        const confirm = async () => {
            if(token.value){
                let quotation = {
                    "age": parseInt(detail.value.personal_data.age),
                    "gender": detail.value.personal_data.gender,
                    "is_smoker": detail.value.smoke=='si',
                    "assured_amount": parseInt(detail.value.assured_amount),
                    "coverages": detail.value.coverages.reverse(),
                    "diseases": detail.value.diseases,
                    "occupations": detail.value.occupation,
                    "sports": detail.value.sports,
                    "activities": detail.value.hobbies,
                    "weight": detail.value.personal_data.weight,
                    "height": detail.value.personal_data.height,
                };

                const result = await generateQuotation(quotation);
                if(result){
                    createStorage('quote-detail', result);
                    next();
                }
            }else{
                Swal.fire({
                    title: "Aviso",
                    html: "Servicio de cotización no disponible, por favor inténtelo de nuevo más tarde",
                    icon: "info",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                }); 
            }
        }

       

        return {
            category,
            changeGoToBack,
            counterGoBack,
            continueQuote,
            next,
            detail,
            confirm,
            isLoading
        }
    },
    components:{
        MenuPrincipal,
        HeaderMobil,
        MenuLateralMobile,
        MenuLateral,
        Footer,
        Loading
    }
}
</script>

<style scoped>
.description {
    font-family: 'roboto-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #485462;
}
</style>